var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tool-page"
  }, [_c('Introduce', {
    attrs: {
      "title": _vm.$t('tools.economic-calendar.title'),
      "titleBefore": _vm.$t('tools.economic-calendar.title-sm1'),
      "titleSm": _vm.$t('tools.economic-calendar.title-sm2'),
      "listFeature": _vm.listFeature
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "content-slot"
        }, [_vm._v(" " + _vm._s(_vm.$t('tools.economic-calendar.content1')) + " "), _c('span', {
          staticClass: "content-text-color"
        }, [_vm._v(" " + _vm._s(_vm.$t('tools.economic-calendar.content2')) + " ")]), _vm._v(" " + _vm._s(_vm.$t('tools.economic-calendar.content3')) + " ")])];
      },
      proxy: true
    }, {
      key: "button",
      fn: function () {
        return [_c('b-button', {
          staticClass: "custom-btn",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.goToLogin();
            }
          }
        }, [_c('div', {
          staticClass: "text-white-button button-large text-nowrap d-flex justify-content-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.start-btn")) + " ")])])];
      },
      proxy: true
    }, {
      key: "itemRight",
      fn: function () {
        return [_c('div', {
          staticClass: "layout-right"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/toolImg/calendar.png")
          }
        })])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "title title-tool-page"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.begin1")) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.begin2")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.calendar")) + " ")])]), _c('div', {
    staticClass: "content-item"
  }, [_c('div', {
    staticClass: "content-item__info"
  }, [_c('div', {
    staticClass: "content-item__info__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-title1")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text1")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text2")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text3")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text4")) + " ")])]), _c('img', {
    attrs: {
      "src": require("@/assets/toolImg/calendar1.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-item hinde-md"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/toolImg/calendar2.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item__info"
  }, [_c('div', {
    staticClass: "content-item__info__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-title2")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text5")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text6")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text7")) + " " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text8")) + " ")])])]), _c('div', {
    staticClass: "content-item"
  }, [_c('div', {
    staticClass: "content-item__info"
  }, [_c('div', {
    staticClass: "content-item__info__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-title3")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text9")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text10")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text11")) + " "), _c('div', {
    staticClass: "enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text12")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text13")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text14")) + " ")])]), _c('img', {
    attrs: {
      "src": require("@/assets/toolImg/calendar3.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-item hinde-sm"
  }, [_c('div', {
    staticClass: "content-item__info"
  }, [_c('div', {
    staticClass: "content-item__info__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-title2")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text5")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text6")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text7")) + " "), _c('div', {
    staticClass: "enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-text8")) + " ")])]), _c('img', {
    attrs: {
      "src": require("@/assets/toolImg/calendar2.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-item-full"
  }, [_c('div', {
    staticClass: "content-item-full__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.content-item-title4")) + " ")]), _c('div', {
    staticClass: "content-item-full__img"
  }, [_c('div', {
    staticClass: "try-out"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.try-out")) + " ")]), _c('img', {
    attrs: {
      "src": require("@/assets/toolImg/calendar4.png"),
      "alt": ""
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }