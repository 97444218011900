<template>
	<div class="tool-page">
		<Introduce
			:title="$t('tools.economic-calendar.title')"
			:titleBefore="$t('tools.economic-calendar.title-sm1')"
			:titleSm="$t('tools.economic-calendar.title-sm2')"
			:listFeature="listFeature"
		>
			<template #content>
				<div class="content-slot">
					{{ $t('tools.economic-calendar.content1') }}
					<span class="content-text-color">
						{{ $t('tools.economic-calendar.content2') }}
					</span>
					{{ $t('tools.economic-calendar.content3') }}
				</div>
			</template>
			<template #button>
				<b-button variant="primary" class="custom-btn" @click="goToLogin()">
          <div class="text-white-button button-large text-nowrap d-flex justify-content-center">
            {{ $t("tools.economic-calendar.start-btn") }}
          </div>
				</b-button>
			</template>
			<template #itemRight>
				<div class="layout-right">
					<img src="@/assets/toolImg/calendar.png" />
				</div>
			</template>
		</Introduce>
		<div class="title title-tool-page">
			{{ $t("tools.economic-calendar.begin1") }}
			<br>
			{{ $t("tools.economic-calendar.begin2") }}
			<span class="text-primary">
				{{ $t("tools.economic-calendar.calendar") }}
			</span>
		</div>
		<!-- row 1 -->
		<div class="content-item">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.economic-calendar.content-item-title1") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.economic-calendar.content-item-text1") }}
					<div class="hinde-sm enter-paragraphs"></div>
					{{ $t("tools.economic-calendar.content-item-text2") }}
					<span class="bold">
						{{ $t("tools.economic-calendar.content-item-text3") }}
					</span>
					{{ $t("tools.economic-calendar.content-item-text4") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/calendar1.png" alt="">
		</div>
		<!-- row 2 -->
		<div class="content-item hinde-md">
			<img src="@/assets/toolImg/calendar2.png" alt="">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.economic-calendar.content-item-title2") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.economic-calendar.content-item-text5") }}
					<span class="bold">
						{{ $t("tools.economic-calendar.content-item-text6") }}
					</span>
					{{ $t("tools.economic-calendar.content-item-text7") }}
					{{ $t("tools.economic-calendar.content-item-text8") }}
				 </div>
			</div>
		</div>
		<!-- row 3 -->
		<div class="content-item">
			<div class="content-item__info">
				<div class="content-item__info__title">
					{{ $t("tools.economic-calendar.content-item-title3") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.economic-calendar.content-item-text9") }}
					<span class="bold">
						{{ $t("tools.economic-calendar.content-item-text10") }}
					</span>
					{{ $t("tools.economic-calendar.content-item-text11") }}
					<div class="enter-paragraphs"></div>
					{{ $t("tools.economic-calendar.content-item-text12") }}
					<span class="bold">
						{{ $t("tools.economic-calendar.content-item-text13") }}
					</span>
					{{ $t("tools.economic-calendar.content-item-text14") }}
				</div>
			</div>
			<img src="@/assets/toolImg/calendar3.png" alt="">
		</div>
		<!-- row 2 -->
		<div class="content-item hinde-sm">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.economic-calendar.content-item-title2") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.economic-calendar.content-item-text5") }}
					<span class="bold">
						{{ $t("tools.economic-calendar.content-item-text6") }}
					</span>
					{{ $t("tools.economic-calendar.content-item-text7") }}
					<div class="enter-paragraphs"></div>
					{{ $t("tools.economic-calendar.content-item-text8") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/calendar2.png" alt="">
		</div>
		<!-- row 4 -->
		<div class="content-item-full">
			<div class="content-item-full__title">
				{{ $t("tools.economic-calendar.content-item-title4") }}
			</div>
			<div class="content-item-full__img">
				<div class="try-out">
				{{ $t("tools.economic-calendar.try-out") }}
				</div>
				<img src="@/assets/toolImg/calendar4.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
import Introduce from "@/components/tool/Introduce.vue";
import { mapGetters } from 'vuex';
export default {
	components: {
		Introduce,
	},
	computed: {
    ...mapGetters({
      portal_url: 'portal_url'
    }),
		listFeature() {
			return [
				this.$t("tools.economic-calendar.feature1"),
				this.$t("tools.economic-calendar.feature2"),
				this.$t("tools.economic-calendar.feature3"),
			]
		}
  },
	data() {
		return {
			
		}
	},
	mounted() {

	},
	watch: {

	},
	methods: {
		goToLogin() {
      if (this.portal_url) window.open(`${this.portal_url}/register?lang=${this.$i18n.locale}`, '_blank');
    },
	},
	metaInfo: {
		title: "NOZAX: Economic Calendar",
		meta: [
			{
				name: "description",
				content: "" ,
			},
		],
	},
};
</script>
<style lang="scss" scoped>
.content-slot {
	color: #616A70;
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 24px;
	@media (max-width: 1160px) {
    width: 476px;
		font-size: 20px;
  }
	@media (max-width: 880px) {
    width: 100%;
		font-size: 18px;
		.content-text-color {
			color: var(--primary);
		}
  }
}
.title {
	@media (max-width: 880px) {
		margin-top: 64px;
  }
}
.content-item {
	margin-top: 96px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__info {
		width: 460px;
		&__title {
			font-size: 28px;
			font-weight: 500;
			font-family: Roboto;
			letter-spacing: -0.28px;
			width: 366px;
			color: #484C57;
		}
		&__text {
			font-size: 18px;
			font-weight: 400;
			margin-top: 24px;
			color: #616A70 ;
			.enter-paragraphs {
				height: 10px;
			}
			.bold {
				font-weight: 700;
			}
		}
	}
	@media (max-width: 1160px) {
    &__info {
			width: 333px;
			&__text {
				font-size: 16px;
			}
		}
		img {
			width: 420px;
		}
  }
	@media (max-width: 880px) {
		display: block;
		margin-top: 56px;
    &__info {
			width: 100%;
			&__title {
				font-size: 20px;
				letter-spacing: 0;
				width: 70%;
				min-width: 260px;
			}
			&__text {
				font-size: 18px;
				.bold {
					font-weight: 400;
				}
			}
		}
		img {
			margin-top: 32px;
			width: 100%;
		}
  }
}
.content-item-full {
	margin-top: 150px;
	margin-bottom: 136px;
	text-align: center; 
	&__title {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 50px;
		font-family: Roboto;
		color: #373F41;
	}
	&__img {
		position: relative;
		width: fit-content;
    margin-left: auto;
    margin-right: auto;
		.try-out {
			position: absolute;
			top: -32px;
			right: -70px;
			display: inline-flex;
			height: 128px;
			width: 128px;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-radius: 50%;
			background: var(--primary);
			color: var(--white);
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			font-family: Roboto;
			cursor: pointer;
		}
	}
	@media (max-width: 1160px) {
		margin-bottom: 112px;
		&__img {
			img {
				width: 815px;
			}
			.try-out {
				top: -35px;
				right: -35px;
				height: 120px;
				width: 120px;
				font-size: 22.5px;
			}
		}
  }
	@media (max-width: 880px) {
		margin-top: 56px;
		&__title {
			font-size: 32px;
			margin-bottom: 45px;
		}
		&__img {
			img {
				width: 100%;
			}
			.try-out {
				top: -3vw;
				right: -3vw;
				height: 11.73vw;
				width: 11.73vw;
				font-size: 2.2vw;
			}
		}
  }
}
.layout-right {
	margin-top: 80px;
	img {
		width: 550px;
	}
	@media (max-width: 1160px) {
    position: absolute;
		right: 0;
		margin-top: 217px;
		width: 417px;
		img {
			width: 417px;
		}
  }
	@media (max-width: 880px) {
    position: relative;
		margin-top: 90px;
		width: 100%;
		img {
			width: 100%;
		}
  }
}
</style>